.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-red {
    color: #ff0000;
}

.content {
    display: flex;
    align-items: center;
    height: 100vh;
    justify-content: center;
    -webkit-box-align: center;
    flex-direction: column;
}

.login-page {
    width: 50%;
}

.header-section {
    margin-bottom: 70px;
}

.logo-box {
    width: 135px;
    height: 135px;
}

.txtText {
    font-size: 2rem;
    font-weight: 600;
}

.form-login {}

.form-group {
    margin-bottom: 1.2rem;
}

.form-group label {
    color: #858585;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 5px;
}

.form-group input {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 1rem;
    font-weight: 400;
    color: #858585;
    height: 45px;
    width: 100%;
}

.forgot-password {
    display: flex;
    justify-content: flex-end;
}

.forgot-password a {
    margin-top: 1rem;
    font-size: 1rem;
    color: #578688;
    font-weight: 600;
}

.forgot-password a:hover {
    color: #578688;
}

.btn-login {
    width: 35%;
    height: 45px;
    margin-top: 30px;
    color: #fff !important;
    background-color: #578688 !important;
}

.btn-disabled {
    background-color: #e5e5e5 !important;
    color: #858585 !important;
}

.languageSection {
    margin-top: 25px !important;
    font-size: 25px;
    width: 50%;
    display: block;
    margin: 0 auto;
    text-align: right;
}

@media screen and (max-width: 768px) {
    .login-page {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
    }

    .btn-login {
        width: 70%;
    }
}